import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () => import("@/views/MainPageGuard.vue"),
        children: [
            {
                path: "",
                name: "index",
                component: () => import("@/views/Index.vue"),
            },
            // 회사소개
            {
                path: "company/introduce",
                name: "company/introduce",
                component: () => import("@/views/menu/company/Introduce.vue"),
            },
            {
                path: "company/history",
                name: "company/history",
                component: () => import("@/views/menu/company/History.vue"),
            },
            {
                path: "company/orgChart",
                name: "company/orgChart",
                component: () => import("@/views/menu/company/OrgChart.vue"),
            },
            {
                path: "company/location",
                name: "company/location",
                component: () => import("@/views/menu/company/Location.vue"),
            },
            // 사업소개
            {
                path: "business/callCatch",
                name: "business/callCatch",
                component: () => import("@/views/menu/business/CallCatch.vue"),
            },
            {
                path: "business/eouling",
                name: "business/eouling",
                component: () => import("@/views/menu/business/Eouling.vue"),
            },
            {
                path: "business/drt",
                name: "business/drt",
                component: () => import("@/views/menu/business/Drt.vue"),
            },
            {
                path: "business/dailyPass",
                name: "business/dailyPass",
                component: () => import("@/views/menu/business/DailyPass.vue"),
            },
            {
                path: "business/busErp",
                name: "business/busErp",
                component: () => import("@/views/menu/business/BusErp.vue"),
            },
            {
                path: "business/webrella",
                name: "business/webrella",
                component: () => import("@/views/menu/business/Webrella.vue"),
            },
            // 인재채용 
            {
                path: "employment/introduce",
                name: "employment/introduce",
                component: () => import("@/views/menu/employment/Introduce.vue"),
            },
             // 소식
            {
                path: "news",
                name: "news",
                component: () => import("@/views/menu/news/News.vue"),
            },
            // 문의하기
            {
                path: "contact",
                name: "contact",
                component: () => import("@/views/menu/contact/ContactUs.vue"),
            },
            // 데모
            {
                path: "/demo",
                name: "/demo",
                component: () => import("@/views/menu/demo/Introduce.vue"),
            },
        ]
    },
    {
        path: "/policy/webrella",
        name: "/policy/webrella",
        component: () => import("@/views/others/WebrellaPolicy.vue")
    }
    
]

const router = new VueRouter({
    mode: 'history',
    // 페이지 전환시 스크롤 상단 고정
    scrollBehavior() { 
        return { x: 0, y: 0 };
    },
    base: process.env.BASE_URL,
    routes
})

/******************************************************************************
 *  동일 라우터 주소로 호출시 에러 예외 처리
 *****************************************************************************/
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(() => {});
};

export default router
