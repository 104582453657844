import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from "axios";
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import CurrentMenuPanel from "@/components/common/CurrentMenuPanel";
import AlertDialog from "@/components/common/AlertDialog";

Vue.component("CurrentMenuPanel", CurrentMenuPanel);
Vue.component("AlertDialog", AlertDialog);
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.mixin({
    data() {
        return {
            menuItems: [
                {
                    mainMenu: { name: "회사소개", path: "company" },
                    subMenu: [
                        { name: "회사소개", path: "introduce" },
                        { name: "연혁", path: "history" },
                        { name: "조직도", path: "orgChart" },
                        { name: "오시는길", path: "location" },
                    ]
                },
                {
                    mainMenu: { name: "사업소개", path: "business" },
                    subMenu: [
                        { name: "콜잡이", path: "callCatch" },
                        { name: "어울링", path: "eouling" },
                        { name: "DRT", path: "drt" },
                        // { name: "데일리패스", path: "dailyPass" },
                        { name: "위브렐라", path: "webrella" },
                        { name: "버스업무정보시스템", path: "busErp" },
                    ]
                },
                {
                    mainMenu: { name: "인재채용", path: "employment" },
                    subMenu: [
                        { name: "채용안내", path: "introduce" },
                    ]
                },
                {
                    mainMenu: { name: "소식", path: "news" },
                    subMenu: [
                        { name: "홍보·보도자료", path: "" },
                    ]
                },
                {
                    mainMenu: { name: "CONTACT", path: "contact" },
                    subMenu: [
                        { name: "문의하기", path: "" },
                    ]
                },
                {
                    mainMenu: { name: "데모안내", path: "demo" },
                    subMenu: [
                        { name: "데모시연", path: "" },
                    ]
                },
            ],
        }
    },
})

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
