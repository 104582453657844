/* 
┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┓
┃ @소스코드: 정의 명세서                             ┃
┣━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┫
┣ @설명: 상단 네비게이션 메뉴 컴포넌트
┣ @작성: 신우균, 2023-01-30                     
┣ @내역: 신우균, 2023-01-30, 최초등록
        신우균, 2023-07-23, 코드 리팩토링
┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━┛
*/
<template>
    <div class="menu">
        <div class="menu__bg">
            <h1 class="menu__current">{{ currentSubName }}</h1>
            <div class="menu__wrap">
                <v-btn class="button__home" icon @click="$router.push('/')">
                    <v-icon color="#FFF" large>mdi-home</v-icon>
                </v-btn>
                <div class="menu__select">
                    <div class="select__wrap">
                        <div class="select__menu" @click="dropdownClick(0)">
                            {{ currentMenuName }}
                            <v-icon class="arrow" color="#FFF">mdi-chevron-down</v-icon>
                        </div>
                        <ul 
                            class="dropdown" 
                            status="close" 
                            @mouseleave="dropdownClose(0)"
                        >
                            <li
                                v-for="(item, idx) in menuItems"
                                :key="idx"
                                class="dropdown__item"
                                @click="mainMenuClick(item, idx)"
                            >
                                {{ item.mainMenu.name }}
                            </li>
                        </ul>
                    </div>
                    <div class="select__wrap">
                        <div class="select__menu" @click="dropdownClick(1)">
                            {{ currentSubName }}
                            <v-icon class="arrow" color="#FFF">mdi-chevron-down</v-icon>
                        </div>
                        <ul 
                            class="dropdown" 
                            status="close" 
                            @mouseleave="dropdownClose(1)"
                        >
                            <router-link 
                                v-for="(item, idx) in subMenuItemsFilter"
                                class="dropdown__item"
                                :to="'/' + currentMenuPath + '/' + item.path"
                                :key="idx"
                                tag="li"
                            >
                                {{ item.name }}
                            </router-link>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    props: {
        mode: {
            type: String,
            default: "route"
        },
        propsParams: {
            mainPath: "",
            subPath: "",
        },
    },
    data() {
        return {
            // 현재 페이지 네임
            currentTitle: "",
            // 대메뉴
            currentMenuName: "",
            currentMenuPath: "",
            currentMenuIdx: -1,
            // 소메뉴
            currentSubName: "",
            currentSubPath: "",
        }
    },
    computed: {
        subMenuItemsFilter() {
            return this.currentMenuIdx !== -1
                ? this.menuItems[this.currentMenuIdx].subMenu
                : [];
        }
    },
    created() {
        this.init();
    },
    methods: {
        /**********************************************************************
         *  @method     : 초기 설정
         *  @param      { undefined }
         *  @returns    { undefined }
         *********************************************************************/
        init() {
            const routePath = this.$route.path.substring(1).split("/");
            // 대메뉴 검색
            const findMenuIdx = this.menuItems.findIndex((item) => {
                return item.mainMenu.path === routePath[0];
            });
            if (findMenuIdx === -1) {
                this.currentTitle = "undefined";
                this.currentMenuName = "undefined";
                this.currentSubName = "undefined";
                return;
            }
            this.currentMenuName = this.menuItems[findMenuIdx].mainMenu.name;
            this.currentMenuPath = this.menuItems[findMenuIdx].mainMenu.path;
            this.currentMenuIdx = findMenuIdx;
            // 소메뉴 검색
            if (routePath.length < 2) {
                // 싱글 대메뉴주소일 경우 (/demo, /contact)
                this.currentSubName = this.menuItems[findMenuIdx].subMenu.length === 1
                                    ? this.menuItems[findMenuIdx].subMenu[0].name
                                    : "undefined";
                return;
            }
            const findSubIdx = this.menuItems[findMenuIdx].subMenu.findIndex((item) => {
                return item.path === routePath[1];
            });
            this.currentSubName = findSubIdx !== -1 
                                ? this.menuItems[findMenuIdx].subMenu[findSubIdx].name
                                : "undefined";
        },

        /**********************************************************************
         *  @method     : 드롭다운 클릭 이벤트 핸들러
         *  @param      { number }      :element order number
         *  @returns    { undefined }
         *********************************************************************/
        dropdownClick(idx) {
            console.log(idx);
            const selectWrap = document.getElementsByClassName("select__wrap")[idx];
            const dropdown = selectWrap.querySelector(".dropdown");
            const dropdownItem = dropdown.childNodes;
            const dropdownItemLen = dropdown.childElementCount;
            const menuArrow = document.getElementsByClassName("arrow")[idx];
            
            if (dropdown.getAttribute("status") === "close") {
                let dropdownHeight = 0;
                for (let i = 0; i < dropdownItemLen; i++) {
                    dropdownHeight += dropdownItem[i].offsetHeight;
                }
                dropdown.style.height = dropdownHeight + "px";
                dropdown.setAttribute("status", "open");
                menuArrow.classList.add("arrow__open");
            } else {
                dropdown.style.height = 0;
                dropdown.setAttribute("status", "close");
                menuArrow.classList.remove("arrow__open");
            }
        },

         /**********************************************************************
         *  @method     : 드롭다운 닫기 이벤트 핸들러
         *  @param      { number }      :element order number
         *  @returns    { undefined }
         *********************************************************************/
        dropdownClose(idx) {
            const selectWrap = document.getElementsByClassName("select__wrap")[idx];
            const dropdown = selectWrap.querySelector(".dropdown");
            const menuArrow = document.getElementsByClassName("arrow")[idx];
            dropdown.style.height = 0;
            dropdown.setAttribute("status", "close");
            menuArrow.classList.remove("arrow__open");
        },

        /**********************************************************************
         *  @method     : 대메뉴 드롭다운 아이템 이벤트 핸들러
         *  @param      { object }
         *  @param      { number }      :element order number
         *  @returns    { undefined }
         *********************************************************************/
        mainMenuClick(item, idx) {
            this.currentMenuIdx = idx;
            this.currentMenuName = item.mainMenu.name;
            this.currentMenuPath = item.mainMenu.path;
            this.dropdownClose(0);
        }
    }
}
</script>
<style lang="scss" scoped>
    @mixin separator($left: none, $right: none) {
        content: "";
        position: absolute;
        width: 1px;
        height: 18px;
        top: 50%;
        left: $left;
        right: $right;
        transform: translateY(calc(-50% + 1.5px));
        background: #9F9F9F;
    }
    .menu {
        position: relative;
        &__bg {
            width: 100%;
            max-width: 1920px;
            margin: 0 auto;
            text-align: center;
            &::before {
                content: "";
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                background-size: cover;
                background-image: url("@/assets/imgs/main_bg.jpg");
                background-position: center center;
                opacity: 0.6;
            }
        }
        &__current {
            position: relative;
            top: 50%;
            z-index: 2;
            transform: translateY(-50%);
            display: inline-block;
            max-width: 90%;
            &::before {
                content: "";
                position: absolute;
                right: 0;
                bottom: -5px;
                z-index: -1;
                width: 100%;
                height: 20px;
                background: #FFD500;
            }
        }
        &__wrap {
            position: absolute;
            bottom: 0px;
            right: 50%;
            transform: translateX(50%);
            z-index: 2;
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 1400px;
            height: 70px;
            background-color: rgba(0, 0, 0, 0.8);
        }
        &__select {
            display: flex;
            width: 100%;
            padding: 0 20px;
        }
    }
    .select {
        &__wrap {
            position: relative;
            &::before { @include separator($left: 0px); }
            &::after { @include separator($right: -1px); }
        }
        &__menu {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 70px;
            color: #FFF;
            padding: 0 10px 0 20px;
            cursor: pointer;
            user-select: none;
        }
    }
    .arrow {
        &__open { transform: rotate(180deg); }
    }
    .dropdown {
        position: absolute;
        top: 70px;
        width: 100%;
        height: 0px;
        overflow: hidden;
        transition: height 0.5s ease-in-out;
        padding-left: 0px;
        &__item {
            position: relative;
            display: flex;
            align-items: center;
            max-width: 230px;
            line-height: 1.5;
            padding: 10px 20px;
            color: #000;
            word-break: break-all;
            background: #FFF;
            border-left: 1px solid rgba(0, 0, 0, 0.8);
            border-right: 1px solid rgba(0, 0, 0, 0.8);
            text-align: left;
            cursor: pointer;
            &:last-child {
                border-bottom: 1px solid rgba(0, 0, 0, 0.8);
            }
        }
    }
    @include media(desktop) {
        .menu {
            &__bg { height: 490px; }
            &__wrap { padding: 0 20px; }
        }
        .select {
            &__wrap { width: 230px; }
            &__menu { font-size: 18px; }
        }
        .dropdown {
            width: 230px;
        }
    }
    @include media(tablet) {
        .menu {
            &__bg { height: 220px; }
            &__wrap { padding: 0 20px; }
            &__current {
                transform: translateY(calc(50% - 88px));
            }
        }
        .select {
            &__wrap {
                width: 100%;
                max-width: 230px;
            }
        }
    }
    @include media(mobile) {
        .menu {
            &__bg { height: 220px; }
            &__current {
                transform: translateY(calc(50% - 88px));
            }
        }
        .select {
            &__wrap {
                width: 100%;
                max-width: 230px;
            }
        }
        .button__home { display: none; }
    }
    @media (max-width: 350px) {
        .select__menu { font-size: 15px; }
    }
</style>